.rs-latest-events {
  &.style1 {
    .single-img {
      z-index: 1;
      position: relative;
      img {
        border-radius: 3px;
      }
    }
    .event-wrap {
      z-index: 1;
      position: relative;
      .events-short {
        overflow: hidden;
        background: $whiteColor;
        padding: 31px 30px;
        border-radius: 3px;
        .date-part {
          width: 100px;
          float: left;
          text-align: center;
          padding: 11px 0 22px;
          border-radius: 3px;
          margin-right: 25px;
          .month {
            margin-bottom: 10px;
            display: block;
          }
          .date {
            font-size: 50px;
            font-family: $titleFont;
            color: $titleColor2;
            font-weight: 700;
          }
          &.bgc1 {
            background: #dcf1fd;
          }
          &.bgc2 {
            background: #e9fbd5;
          }
          &.bgc3 {
            background: #fee2c0;
          }
        }
        .content-part {
          .categorie {
            a {
              color: $bodyColor;
              &:hover {
                color: $primaryColor;
              }
            }
          }
          .title {
            line-height: 1.5;
            a {
              color: $titleColor2;
              &:hover {
                color: $primaryColor;
              }
            }
          }
        }
      }
      .btn-part {
        a {
          text-transform: uppercase;
          font-weight: 500;
          font-size: 16px;
          color: $titleColor2;
          position: relative;
          display: inline-block;
          &:after {
            position: absolute;
            content: "";
            right: 0;
            bottom: 3px;
            width: 0;
            height: 1px;
            background: $titleColor2;
            transition: $transition;
          }
          &:hover {
            &:after {
              left: 0;
              right: auto;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &.bg-wrap {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 63%;
      height: 100%;
      background: $grayColor;
    }
  }
}

.rs-event {
  .event-item {
    transition: $transition;
    position: relative;
    .event-short {
      text-align: left;
      box-shadow: 0 0 20px #eee;
      .featured-img {
        position: relative;
        img {
          border-radius: 5px 5px 0 0;
          height: 209px;
          width: 100%;
        }
      }
      .categorie {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 1;
        a {
          background: $primaryColor;
          padding: 5px 20px;
          display: inline-block;
          color: $whiteColor;
          border-radius: 4px;
          &:hover {
            opacity: 0.9;
          }
        }
      }
      .content-part {
        padding: 25px 30px 15px 30px;
        border-radius: 0 0 5px 5px;
        .address {
          padding-bottom: 15px;
          color: #363636;
          font-size: 14px;
          i {
            font-size: 13px;
            margin-right: 4px;
          }
        }
        .title {
          font-size: 26px;
          line-height: 32px;
          transition: all 0.8s ease;
          a {
            color: #202020;
            &:hover {
              color: $primaryColor;
            }
          }
        }
        .text {
          color: #363636;
          margin: 0;
          font-size: 14px;
        }
        .event-btm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #efefef;
          margin-top: 34px;
          padding-top: 26px;
          color: $bodyColor;
          .date-part {
            font-size: 13px;
            .date {
              i {
                font-size: 13px;
                margin-right: 7px;
              }
            }
          }
          .btn-part {
            a {
              background: $primaryColor;
              text-transform: capitalize;
              padding: 7px 15px;
              color: $secondaryColor;
              border-radius: 2px;
              font-size: 14px;
              display: inline-block;
              &:hover {
                background: darken($primaryColor, 10%);
              }
            }
          }
        }
      }
    }
    &:hover {
      transform: translateY(-10px);
    }
    &.home12-style {
      .event-short {
        text-align: left;
        background: $whiteColor;
        .featured-img {
          position: relative;
          img {
            border-radius: 5px 5px 0 0;
          }
        }
        .content-part {
          padding: 25px 30px 30px 30px;
          border-radius: 0 0 5px 5px;
          .all-dates-time {
            display: flex;
            .address {
              color: $bodyColor2;
              padding-right: 20px;
              i {
                &:before {
                  color: $greenColor;
                }
              }
            }
            .time {
              color: $bodyColor2;
              font-size: 14px;
              i {
                &:before {
                  color: $greenColor;
                  padding-right: 5px;
                }
              }
            }
          }
          .title {
            font-size: 22px;
            line-height: 32px;
            transition: all 0.8s ease;
            a {
              color: #202020;
              &:hover {
                color: $greenColor;
              }
            }
          }
          .event-btm {
            border-top: 1px solid #efefef;
            .date-part {
              font-size: 13px;
              color: $bodyColor2;
              .date {
                i {
                  font-size: 13px;
                  margin-right: 7px;
                  color: $greenColor;
                }
              }
            }
            .btn-part {
              a {
                background: $greenColor;
                &:hover {
                  background: darken($greenColor, 10%);
                }
              }
            }
          }
        }
      }
      .slick-dots {
        text-align: center;
        li button {
          width: 40px;
          height: 10px;
          display: inline-block;
          margin: 0 6px;
          padding: 3px 0;
          border-radius: 30px;
          background: red;
          border: none;
          &:hover {
            width: 40px;
            opacity: 1;
          }
        }
        .slick-active button {
          background: red;
        }
      }
    }
  }
  &.home12style {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 150px 0;
    .slick-dots {
      text-align: center;
      margin-top: 47px;
      li {
        button {
          width: 25px;
          height: 8px;
          display: inline-block;
          margin: 0 6px;
          padding: 3px 0;
          border-radius: 30px;
          background: darken($greenColor, 10%);
          border: none;
          transition: $transition;
        }
        &:hover button,
        &.slick-active button {
          width: 40px;
          background: $greenColor;
          opacity: 0.5;
        }
      }
    }
  }
  &.modify1 {
    .event-item {
      .event-short {
        .featured-img {
          .dates {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #21a7d0;
            padding: 7px 20px;
            display: inline-block;
            color: $secondaryColor;
            border-radius: 15px 15px 0 0;
            right: 0;
            margin: 0 auto;
            max-width: 220px;
            text-align: center;
          }
        }

        .content-part {
          padding: 30px 30px 35px 30px;
          text-align: center;
          .title {
            font-size: 24px;
          }
          .time-sec {
            display: inline-flex;
            .timesec {
              margin-right: 15px;
              font-size: 14px;
              i {
                margin-right: 5px;
                &:before {
                  font-size: 16px;
                }
              }
            }
            .address {
              color: #363636;
              font-size: 14px;
              i {
                font-size: 13px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
  &.modify2 {
    .event-item {
      .event-short {
        transition: all 500ms ease;
        box-shadow: 0 0 20px #eee;
        position: relative;
        .featured-img {
          position: relative;
          padding: 0px 0px 0px 0px;
          &:before {
            opacity: 0.85;
            background: 0 0;
            background: linear-gradient(transparent, #333333),
              linear-gradient(transparent, #333333);
            background: -moz-linear-gradient(transparent, #333333),
              -moz-linear-gradient(transparent, #333333);
            background: -o-linear-gradient(transparent, #333333),
              -o-linear-gradient(transparent, #333333);
            content: "";
            height: 100%;
            bottom: 0;
            left: 0px;
            right: 0px;
            position: absolute;
            z-index: 1;
            transition: all 0.7s ease 0s;
          }
        }
        .content-part {
          padding: 40px 40px 40px 40px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          border-radius: 0 0 5px 5px;
          .title {
            a {
              color: $whiteColor;
            }
          }
          .address {
            color: $whiteColor;
            font-size: 14px;
            i {
              font-size: 13px;
              margin-right: 4px;
            }
          }
          .date-part {
            padding: 0px 0px 10px 0px;
            .date {
              color: $whiteColor;
              font-size: 13px;
              i {
                font-size: 13px;
                margin-right: 7px;
              }
            }
          }
          .time {
            color: $whiteColor;
            font-size: 14px;
            i {
              &:before {
                margin: 0;
                font-size: 14px;
                color: #fff;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
  &.home8-style1 {
    .rs-carousel {
      .slick-dots {
        text-align: center;
        li button {
          width: 40px;
          height: 10px;
          display: inline-block;
          margin: 0 6px;
          padding: 3px 0;
          border-radius: 30px;
          background: $primaryColor;
          border: none;
          &:hover {
            width: 40px;
            opacity: 1;
          }
        }
        .slick-active button {
          background: $primaryColor;
        }
      }
    }
    .event-item {
      .event-short {
        .featured-img {
          .dates {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: $primaryColor;
            padding: 7px 20px;
            display: inline-block;
            color: #fff;
            border-radius: 15px 15px 0 0;
            right: 0;
            margin: 0 auto;
            max-width: 220px;
            text-align: center;
          }
        }

        .content-part {
          padding: 30px 30px 35px 30px;
          text-align: center;
          .title {
            font-size: 24px;
          }
          .time-sec {
            display: inline-flex;
            .timesec {
              padding-right: 4px;
              i {
                margin-right: 5px;
                &:before {
                  color: $primaryColor;
                  font-size: 14px;
                }
              }
            }
            .address {
              font-size: 14px;
              i {
                font-size: 13px;
                margin-right: 5px;
                &:before {
                  color: $primaryColor;
                }
              }
            }
          }
        }
      }
    }
  }
  &.orange-style {
    &.modify1 {
      .event-item {
        .event-short {
          .featured-img {
            .dates {
              background: $primaryColor;
            }
          }
        }
      }
    }
  }
}

/* Custom Container Width :1440px. */
@media (min-width: 1440px) {
}

/* Laptop :1600px. */
@media #{$laptop} {
}

/* XL Device :1366px. */
@media #{$xl} {
}

/* Lg Device :1199px. */
@media #{$lg} {
  .rs-latest-events.style1 .event-wrap .events-short {
    padding: 25px;
  }
  .rs-event .event-item.home12-style .event-short .content-part {
    padding: 25px 20px 30px 20px;
  }
  .rs-event .event-item.home12-style .event-short .content-part .title {
    font-size: 20px;
    line-height: 30px;
  }
  .rs-event.modify1 .event-item .event-short .content-part .title {
    font-size: 20px;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part .title {
    font-size: 21px;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part {
    padding: 30px 30px 25px 20px;
    text-align: center;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part .time-sec {
    display: unset;
  }
  .rs-event.modify1 .event-item .event-short .content-part .time-sec .timesec {
    margin-right: 0;
  }
  .rs-event.modify1 .event-item .event-short .content-part .time-sec {
    display: block;
  }
  .rs-event .event-item .event-short .content-part .title {
    margin-bottom: 15px;
  }
  .rs-event.modify2 .event-item .event-short .content-part {
    padding: 30px 20px;
  }
  .rs-event .event-item .event-short .content-part .title {
    font-size: 26px;
    line-height: 28px;
  }

  .rs-latest-events.style1 .event-wrap {
    padding-bottom: 50px;
  }
  .rs-latest-events.style1 .event-wrap .events-short .content-part .title {
    font-size: 22px;
  }
  .rs-testimonial.home12-style .testi-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {
  .rs-event .event-item .event-short .content-part .title {
    font-size: 22px;
  }
  .rs-event .event-item .event-short .content-part {
    padding: 25px 15px 15px 25px;
  }
}

/* Tablet desktop :991px. */
@media #{$md} {
  .rs-latest-events.bg-wrap:after {
    display: none;
  }
  .rs-latest-events.style1 .event-wrap .events-short {
    display: flex;
    align-items: center;
    padding: 25px 0;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part {
    padding: 25px 20px 30px;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part .time-sec {
    display: inline-flex;
  }
  .rs-event.modify1 .event-item .event-short .content-part {
    text-align: left;
  }
  .rs-event.modify1 .event-item .event-short .content-part .title {
    text-align: left;
    margin-bottom: 10px;
  }
  .rs-event.modify1 .event-item .event-short .content-part {
    padding: 30px 30px 23px 30px;
  }
  .rs-latest-events.style1 .event-wrap {
    padding-bottom: 0;
  }
}

/* Tablet :767px. */
@media #{$sm} {
  .rs-event .event-item .event-short .featured-img img {
    width: 100%;
  }
}

/* Large Mobile :575px. */
@media #{$xs} {
  .rs-latest-events.style1 .event-wrap .events-short {
    display: block;
  }
  .rs-latest-events.style1 .event-wrap .events-short {
    padding: 0;
  }
  .rs-latest-events.style1 .event-wrap .events-short .date-part {
    float: unset;
    width: 100%;
    margin: 0 0 25px;
  }
}

/* small mobile :480px. */
@media #{$mobile} {
  .rs-event
    .event-item.home12-style
    .event-short
    .content-part
    .all-dates-time
    .address {
    padding-right: 0px;
    padding-bottom: 5px;
  }
  .rs-download-app .mobile-img .apps-image img {
    max-width: 140px;
  }
  .rs-event
    .event-item.home12-style
    .event-short
    .content-part
    .all-dates-time {
    display: block;
    padding-bottom: 10px;
  }
  .rs-event
    .event-item.home12-style
    .event-short
    .content-part
    .event-btm
    .btn-part
    a {
    padding: 5px 13px;
    font-size: 13px;
  }
  .rs-event.home8-style1 .event-item .event-short .content-part .time-sec {
    display: unset;
  }
  .rs-event .event-item .event-short .content-part .title {
    font-size: 18px;
    line-height: 28px;
  }
  .rs-event .event-item .event-short .content-part {
    padding: 15px 15px 15px 20px;
  }
  .rs-event.modify1 .event-item .event-short .content-part .time-sec {
    display: block;
  }
  .rs-event.modify1 .event-item .event-short .content-part .time-sec .timesec {
    margin-right: 0;
  }
}
